<template>
  <div class="MyFollow">
    <!-- <p class="pageTop"></p> -->
    <div class="PageCommon">
      <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
            <h3   class="pageTitle" >
              <span v-if="!mobileFlag">
                {{ $t("MyFollowers_Myfollowers_list") }}

              </span>
              <div class="refreshListBtnDiv">
                <el-button class="confirmDialogBtn" @click="getData()" >

                  {{
                    $t("ManualReplenishment_refresh")
                  }}
                  <i class="el-icon-refresh-right"></i>
                </el-button>
              </div>
            </h3>

            <div class="tableDiv" v-if="!mobileFlag">
              <el-table :data="tableData" border style="width: 100%" ref="dataTable" :summary-method="getSummaries" show-summary>
                <el-table-column width="80" v-if="!mobileFlag" :fixed="tableData.length !== 0 ? true : false" prop="sortNum"
                  :label="$t('MyFollowers_Number')">
                </el-table-column>
                <el-table-column :fixed="tableData.length !== 0 ? true : false" width="100" prop="account"
                  :label="$t('MyFollowers_MT4Account')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.status == 1"  class="mt4Click" @click="toEcharts(scope.row.account)">
                      {{ scope.row.account }}
                    </div>
                    <div v-else >
                      {{ scope.row.account }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :width="flexLabelWidth($t('MyFollowers_SignalSource'))" prop="portfolioName"
                  :label="$t('MyFollowers_SignalSource')">
                </el-table-column>
                <el-table-column :width="flexLabelWidth($t('MyFollowers_SignalSourceId'))" prop="portfolioLoginId"
                  :label="$t('MyFollowers_SignalSourceId')">
                </el-table-column>
                <el-table-column prop="beginPeriodEquity" :label="$t('InitialNetValue')">
                  <template slot-scope="scope">
                    <div>
                      {{  scope.row.beginPeriodEquity?formatNumberFractionDigits(scope.row.beginPeriodEquity)  : 0.00 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="currentPeriodDeposit" :label="$t('CurrentDeposit')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.currentPeriodDeposit?formatNumberFractionDigits(scope.row.currentPeriodDeposit) : 0.00 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="equity" :label="$t('currentNetWorth')">
                  <template slot-scope="scope">
                    <div>
                      {{  scope.row.equity?formatNumberFractionDigits(scope.row.equity)  : 0.00  }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="balance" :label="$t('MyFollowers_Balance')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.balance?formatNumberFractionDigits(scope.row.balance)   : 0.00  }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :width="flexLabelWidth($t('MyFollowers_PL'))" prop="profit"
                  :label="$t('MyFollowers_PL')">
                  <template slot-scope="scope">
                  <div :class="scope.row.profit > 0?'greenNum':'redNum'">
                    {{  scope.row.profit ?formatNumberFractionDigits(scope.row.profit) : 0.00  }}
                  </div>
                </template>
                </el-table-column>
                <el-table-column :width="flexLabelWidth($t('MyFollowers_MarginRatio'))" prop="marginLevel"
                  :label="$t('MyFollowers_MarginRatio')">
                  <template slot-scope="scope">
                    <div>
                      {{
                        scope.row.marginLevel ? scope.row.marginLevel + "%" : 0
                      }}
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="margin" :width="flexLabelWidth($t('MyFollowers_Margin'))"
                  :label="$t('MyFollowers_Margin')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.margin ? scope.row.margin : 0 }}
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column :width="flexLabelWidth($t('MyFollowers_FreeMargin'))" prop="freeMargin"
                  :label="$t('MyFollowers_FreeMargin')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.freeMargin ? formatNumberFractionDigits(scope.row.freeMargin) : 0 }}
                    </div>
                  </template>
                </el-table-column>
               
                <el-table-column :width="flexLabelWidth($t('CurrentTradingVolume'))" prop="volume"
                  :label="$t('CurrentTradingVolume')">
                  <template slot-scope="scope">
                    <div>
                      {{
                        scope.row.currentPeriodVolumes ? scope.row.currentPeriodVolumes  : 0 
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="160" prop="currentSettlementBeginTime" :label="$t('startTimeOfThisPeriod')">
                  
                </el-table-column>
                <el-table-column width="160" prop="currentSettlementEndTime" :label="$t('settlementTimeOfThisPeriod')">
                  
                </el-table-column>
                <!-- <el-table-column prop="credit" :label="$t('MyFollowers_Bonus')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.credit ? scope.row.credit : 0 }}
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column :label="$t('MyFollowers_Status')" :fixed="tableData.length !== 0 ? 'right' : false">
                  <template slot-scope="scope">
                    <div>
                      <span v-if="scope.row.status == 0" class="statusGreen">{{
                        $t("MyFollowers_BeingInvited")
                      }}</span>
                      <span v-if="scope.row.status == 1" class="statusYellow">{{
                        $t("MyFollowers_Copying")
                      }}</span>
                      <span v-if="scope.row.status == 2" class="statusRed">{{
                        $t("MyFollowers_InviteRejected")
                      }}</span>
                      <span v-if="scope.row.status == 3" class="statusRed">
                        {{ $t("MyFollowers_StopCopying1") }}
                      </span>
                      <span v-if="scope.row.status == 4" class="statusYellow">{{
                        $t("MyFollowers_InviteSuccessNoActive")
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                
                <el-table-column :fixed="tableData.length !== 0 ? 'right' : false" v-if="!mobileFlag" width="180"
                  :label="$t('MyFollowers_Operation')">
                  <template slot-scope="scope">
                    <!-- {{ scope.row.insTime | dateFilter }} -->
                    <div class="operateBtns">
                      <el-button v-if="scope.row.status == 1 || scope.row.status == 4" @click="getSetting(scope.row)"
                        type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                        <svg-icon class="greenIcon" icon-class="SettingIcon"></svg-icon>
                        {{ $t("MyFollowers_Setting") }}
                      </el-button>
                      <el-button v-if="scope.row.status == 1 || scope.row.status == 4" @click="stopFollow(scope.row)"
                        type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                        <svg-icon class="greenIcon" icon-class="StopIcon"></svg-icon>
                        {{ $t("MyFollowers_StopCopying") }}
                      </el-button>
                      <el-button v-if="scope.row.status == 0" @click="checkFollow(scope.row)" type="text" size="small"
                        v-loading.fullscreen.lock="fullscreenLoading">
                        <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                        {{ $t("MyFollowers_ViewInvitation") }}
                      </el-button>
                      <span v-if="scope.row.status == 2"> - </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :fixed="tableData.length !== 0 ? 'right' : false" v-if="mobileFlag" width="80"
                  :label="$t('MyFollowers_Operation')">
                  <template slot-scope="scope">
                    <!-- {{ scope.row.insTime | dateFilter }} -->
                    <div class="operateBtns">
                      <el-popover placement="bottom-end" trigger="click">
                        <el-button v-if="scope.row.status == 1 || scope.row.status == 4" @click="getSetting(scope.row)"
                          type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="SettingIcon"></svg-icon>
                          {{ $t("MyFollowers_Setting") }}
                        </el-button>
                        <el-button v-if="scope.row.status == 1 || scope.row.status == 4" @click="stopFollow(scope.row)"
                          type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="StopIcon"></svg-icon>
                          {{ $t("MyFollowers_StopCopying") }}
                        </el-button>
                        <el-button v-if="scope.row.status == 0" @click="checkFollow(scope.row)" type="text" size="small"
                          v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                          {{ $t("MyFollowers_ViewInvitation") }}
                        </el-button>
                        <span v-if="scope.row.status == 2"> - </span>
                        <i slot="reference" class="mobileSetting el-icon-setting"></i>
                      </el-popover>

                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tableTotalNum">
                {{ $t('tableTotalvolumns') + ' : ' + totalVolume + ' ' + $t('tableTotalprofit') + ' : ' + totalProfit + ' '}}
              </div>
              <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')"
                :next-text="$t('MyFollowers_NexPage')" @current-change="handleCurrentChange" :current-page="currentPage"
                @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]" :pager-count="5" :page-size="pageSize"
                layout="prev, pager, next, sizes" :total="total">
              </el-pagination>
            </div>

              <div class="mobileTableList" v-show="mobileFlag">
                    <div class="tableTotalTop">
                      <span>{{ $t('tableTotalvolumns') }}</span>
                      <b>
                        {{ totalVolume }}
                      </b>
                    </div>
                    <div class="tableTotalTop">
                      <span>{{ $t('tableTotalprofit') }}</span>
                      <b :class="[totalProfit > 0 ?'greenNum':'redNum']">
                        {{ totalProfit }}
                      </b>
                    </div>
                    <el-collapse accordion  @change="collapseChange">
                        <el-collapse-item v-for="(item,index) in tableData" :key="index">
                        <template slot="title">
                          <div class="mobileTableCard" @click.stop="showCollapse()">
                            <div class="mobileTableItem mobileTableStatusTitle">
                              <div >
                                <span v-if="item.status == 0" class="statusGreen">{{
                                    $t("MyFollowers_BeingInvited")
                                  }}</span>
                                  <span v-if="item.status == 1" class="statusYellow">{{
                                    $t("MyFollowers_Copying")
                                  }}</span>
                                  <span v-if="item.status == 2" class="statusRed">{{
                                    $t("MyFollowers_InviteRejected")
                                  }}</span>
                                  <span v-if="item.status == 3" class="statusRed">
                                    {{ $t("MyFollowers_StopCopying1") }}
                                  </span>
                                  <span v-if="item.status == 4" class="statusYellow">{{
                                    $t("MyFollowers_InviteSuccessNoActive")
                                  }}</span>
                              </div>
                            </div>
                              <div class="mobileTableItem">
                              <p class="mobileTableTitle" >
                                  {{ $t('MyFollowers_MT4Account') }}
                              </p>
                              <p v-if="item.status == 1" class="mobileTableContent mobileUnderline" @click="toEcharts(item.account)">

                                  {{ item.account }}
                              </p>
                              <p v-else class="mobileTableContent ">
                                {{ item.account }}
                              </p>


                              </div>
                              <div class="mobileTableItem">
                              <p class="mobileTableTitle" >
                                  {{ $t('MyFollowers_SignalSource') }}
                              </p>
                              <p class="mobileTableContent" >
                                  {{  item.portfolioName  }}
                              </p>
                              </div>
                              <div class="mobileTableItem">
                              <p class="mobileTableTitle" >
                                  {{ $t('MyFollowers_SignalSourceId') }}
                              </p>
                              <p class="mobileTableContent" >
                                  {{  item.portfolioLoginId  }}
                              </p>
                              </div>
                              <div class="mobileTableItem">
                              <p class="mobileTableTitle" >
                                  {{ $t('InitialNetValue') }}
                              </p>
                              <p class="mobileTableContent" >
                                  {{  item.beginPeriodEquity ? formatNumberFractionDigits(item.beginPeriodEquity) : 0.00 }}
                              </p>
                              </div>

                          </div>
                          <div class="mobileTableBtns" @click.stop="showCollapse()">
                            <el-button class="mobileBlueBtn" v-if="item.status == 1 || item.status == 4" @click="getSetting(item)"
                              type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="SettingIcon"></svg-icon>
                              {{ $t("MyFollowers_Setting") }}
                            </el-button>
                            <el-button class="mobileBlueBtn" v-if="item.status == 1 || item.status == 4" @click="stopFollow(item)"
                              type="text" size="small" v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="StopIcon"></svg-icon>
                              {{ $t("MyFollowers_StopCopying") }}
                            </el-button>
                            <el-button class="mobileBlueBtn" v-if="item.status == 0" @click="checkFollow(item)" type="text" size="small"
                              v-loading.fullscreen.lock="fullscreenLoading">
                              <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                              {{ $t("MyFollowers_ViewInvitation") }}
                            </el-button>
                          </div>
                        </template>
                        <div class="mobileTableCard" >
                            <div class="mobileTableItem">
                              <p class="mobileTableTitle" >
                                  {{ $t('CurrentDeposit') }}
                              </p>
                              <p  class="mobileTableContent">
                                {{ item.currentPeriodDeposit ?formatNumberFractionDigits(item.currentPeriodDeposit)  : 0.00 }}
                              </p>
                              </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('currentNetWorth') }}
                            </p>
                            <p class="mobileTableContent" >
                              {{ item.equity ? formatNumberFractionDigits(item.equity) : 0.00 }}
                            </p>
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('MyFollowers_Balance') }}
                            </p>
                            <p class="mobileTableContent" >
                              {{ item.balance ?formatNumberFractionDigits(item.balance)  : 0.00 }}
                            </p>
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('MyFollowers_PL') }}
                            </p>
                            <p class="mobileTableContent" >
                              {{ item.profit ? formatNumberFractionDigits(item.profit)  : 0.00 }}
                            </p>
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('MyFollowers_MarginRatio') }}
                            </p>
                            <p  class="mobileTableContent">
                                {{  item.marginLevel ? item.marginLevel + "%"  : 0 }}
                            </p>
                            </div>
                            <div class="mobileTableItem">
                              <p class="mobileTableTitle" >
                                {{ $t('MyFollowers_FreeMargin') }}
                              </p>
                              <p class="mobileTableContent" >
                              {{ item.freeMargin ? formatNumberFractionDigits(item.freeMargin)  : 0.00 }}
                            </p>
                              <!-- <p :class="[item.profit > 0?'greenNum':'redNum','mobileTableContent']">
                                {{ item.profit ? item.profit : 0  }}
                              </p> -->
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('CurrentTradingVolume') }}
                            </p>
                            <p  class="mobileTableContent">
                                {{  item.currentPeriodVolumes ? item.currentPeriodVolumes : 0 }}
                            </p>
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('startTimeOfThisPeriod') }}
                            </p>
                            <p  class="mobileTableContent">
                                {{  item.currentSettlementBeginTime }}
                            </p>
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('settlementTimeOfThisPeriod') }}
                            </p>
                            <p  class="mobileTableContent">
                                {{  item.currentSettlementEndTime }}
                            </p>
                            </div>
                        </div>
                    </el-collapse-item>

                    </el-collapse>

                    <div class="x" v-if="up && tableData.length < total">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && tableData.length >= total">

                      {{ $t('noMoreData') }}
                    </div>
                </div>

      <!-- <h3 class="pageTitle">
                {{ $t('MyFollowers_Myfollowers') }}
            </h3> -->
          </div>
      </div>
    </div>
    <el-drawer :title="followDrawerTitle" class="drawerCommon checkInviteDrawer" :append-to-body="true"
      @close="closeDrawer()" :close-on-press-escape="false" :visible.sync="checkInviteDrawer" direction="rtl">
      <div class="drawerContent">
        <el-form :model="followForm" :rules="followRule" ref="followForm" label-position="right" label-width="150px"
          class="drawerForm" :label-position="mobileFlag ? 'top' : 'left'">
          <h3 class="checkInviteDrawerTitle">
            <i></i> {{ $t("MyFollowers_ViewParametersOfBeingInvitedtoCopy") }}
          </h3>
          <!-- <p class="checkInviteDrawerSubtitle" v-if="checkRow.status == 0">
            {{
              followForm.loginID +
              $t("MyFollowers_VPBIC1") +
              followForm.portfolioName +
              $t("MyFollowers_VPBIC2")
            }}
          </p> -->
          <el-form-item prop="following">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_CopyRatio") }} </span>
                <a href="javascript:;"

                  @click.stop="labelIconClick($t('MyFollowers_CopyRatio'), $t('MyFollowers_copyPercentTxt'),$t('MyFollowers_copyPercentTxt1'))">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>

                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  <div>
                    <div>
                      {{ $t('MyFollowers_copyPercentTxt') }}
                    </div><br />
                    <div>
                      {{ $t('MyFollowers_copyPercentTxt1') }}
                    </div>
                  </div>
                </div>
              </span>
            </span>
            <div class="formPercentDiv">
              <el-input :disabled="checkRow.status !== 1" v-model.number="followForm.following" autocomplete="off"></el-input>
              <b :class="checkRow.status !== 1?'formPercent':'formPercentStatus'">%</b>
              <div class="formPercentSetting" @click="percentSetting">
                设置
              </div>
            </div>
            
          </el-form-item>
          <el-form-item prop="maxVolume">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_MaxLot") }} </span>
                <a href="javascript:;"
                  @click.stop="labelIconClick($t('MyFollowers_MaxLot'), $t('MyFollowers_maxVolumnTxt'))">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  <div>
                    <div>
                      {{ $t('MyFollowers_maxVolumnTxt') }}
                    </div><br />
                    <div>
                      {{ $t('MyFollowers_maxVolumnTxt1') }}
                    </div>
                  </div>
                </div>
              </span>
            </span>
            <el-input-number disabled class="creaseInput" controls-position="right" v-model.number="followForm.maxVolume" :placeholder="$t('nolimit')"
              autocomplete="off"></el-input-number>
              <b class="formLots">lots</b>

          </el-form-item>
          <!-- <el-form-item prop="maxDrawdown">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_MaxDrawdown") }} </span>
                <a href="javascript:;"
                  @click.stop="labelIconClick($t('MyFollowers_MaxDrawdown'), $t('MyFollowers_maxDrawdownTxt'))">
                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  {{ $t("MyFollowers_maxDrawdownTxt") }}
                </div>
              </span>
            </span>
            <el-input-number disabled class="creaseInput" controls-position="right"
              v-model.number="followForm.signalMaxDrawdown" autocomplete="off"></el-input-number>
          </el-form-item> -->
          <el-form-item :label="$t('MyFollowers_Instruments')">
            <el-input disabled v-model="followForm.symbols"></el-input>
          </el-form-item>
          <el-form-item prop="autoRecon">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_WhetherToAutomaticallyReplenishOrders") }}
                </span>
                <a href="javascript:;"
                  @click.stop="labelIconClick($t('MyFollowers_WhetherToAutomaticallyReplenishOrders'), $t('MyFollowers_isAutoTxt'))">

                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_isAutoTxt") }}
                </div>
              </span>
            </span>
            <el-radio-group v-model="followForm.autoRecon" disabled>
              <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
              <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="activation" :label="$t('MyFollowers_ActivateAfterClientConsent')">
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_ActivateAfterClientConsent") }}
                </span>
                <a href="javascript:;"
                  @click.stop="labelIconClick($t('MyFollowers_ActivateAfterClientConsent'), $t('MyFollowers_mainActiveTxt'))">

                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_mainActiveTxt") }}
                </div>
              </span>
            </span>
            <el-radio-group v-model="followForm.activation" disabled>
              <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
              <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <h3 class="checkInviteDrawerTitle">
            <i></i>{{ $t("MyFollowers_SetUpmyCopyAccount") }}
          </h3>
          <el-form-item :label="$t('brokerName')" prop="brokerName">
            <el-select :disabled="isFollowing" v-model="followForm.brokerName" class="drawer_100Input"
              @change="selectChange">
              <el-option v-for="item in brokerList" :key="item.id" :label="item.brokerName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('MyFollowers_ChooseMT4Account')" prop="account">
            <!-- <el-select :disabled="isFollowing" v-model="followForm.account" class="drawer_100Input"
              @change="selectChange">
              <el-option v-for="item in followForm.accountList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select> -->
            <el-input :disabled="isFollowing"  v-model="followForm.account" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item v-if="checkRow.status == 0" :label="$t('MyFollowers_Mt4Password')" prop="cwPassword">

            <el-input type="password" v-model="followForm.cwPassword" autocomplete="off" show-password></el-input>

          </el-form-item>
          <el-form-item :label="$t('server')" prop="serverId">
            <!-- <el-input v-model="followForm.serverName" disabled></el-input> -->
            <el-select
              v-model="followForm.serverId"
              class="allLengthInput"
              :disabled="isFollowing"
            >
              <el-option
                v-for="item in serverList"
                :key="item.serverId"
                :label="item.serverName"
                :value="item.serverId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <span slot="label">
              <span class="label-box">
                <span> {{ $t("MyFollowers_MaxDrawdown") }} </span>
                <a href="javascript:;"
                  @click.stop="labelIconClick($t('MyFollowers_MaxDrawdown'), $t('MyFollowers_maxDrawdownTxt'))">

                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  {{ $t("MyFollowers_maxDrawdownTxt") }}
                </div>
              </span>
            </span>
            <div class="drawerCard">
              <el-form-item prop="maxDrawdown">
                <div class="drawerCardItem">
                  <span class="drawerCard_Label">{{
                    $t("MyFollowers_DrawdownValue")
                  }}</span>
                  <el-input-number class="creaseInput" :min="0" controls-position="right"
                    v-model.number="followForm.maxDrawdown" autocomplete="off"></el-input-number>
                </div>
              </el-form-item>
              <el-form-item prop="maxDrawdownStopFollow">
                <el-checkbox false-label="false" true-label="true" v-model="followForm.maxDrawdownStopFollow">{{
                  $t("MyFollowers_StopCopying1") }}</el-checkbox>
              </el-form-item>
              <el-form-item>
                <div class="drawerCardBtns" v-if="checkRow.status == 1 || checkRow.status == 4">
                  <el-button class="drawerCardBtn" @click="followSettingSave(1)">{{ $t("MyFollowers_Save") }}</el-button>
                </div>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="label-box">
                <span>{{ $t("MyFollowers_StopProfit") }} </span>
                <a href="javascript:;"
                  @click.stop="labelIconClick($t('MyFollowers_StopProfit'), $t('MyFollowers_takeProfitTxt'))">

                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>
                  {{ $t("MyFollowers_takeProfitTxt") }}
                </div>
              </span>
            </span>
            <div class="drawerCard">
              <el-form-item prop="takeProfit">
                <div class="drawerCardItem">
                  <span class="drawerCard_Label">{{
                    $t("MyFollowers_StopProfit")
                  }}</span>
                  <el-input-number class="creaseInput" :min="0" controls-position="right"
                    v-model.number="followForm.takeProfit" autocomplete="off"></el-input-number>
                </div>
              </el-form-item>
              <el-form-item prop="takeProfitStopFollow">
                <el-checkbox false-label="false" true-label="true" v-model="followForm.takeProfitStopFollow">{{
                  $t("MyFollowers_StopCopying1") }}</el-checkbox>
              </el-form-item>
              <el-form-item>
                <div class="drawerCardBtns" v-if="checkRow.status == 1 || checkRow.status == 4">
                  <el-button class="drawerCardBtn" @click="followSettingSave(2)">{{ $t("MyFollowers_Save") }}</el-button>
                </div>
              </el-form-item>
            </div>
          </el-form-item> -->
          <el-form-item :label="$t('MyFollowers_OneClickToClose')" v-if="checkRow.status == 1">
            <div class="drawerCard">
              <el-form-item>
                <div class="drawerCardItem">
                  <span class="drawerCard_Label drawerCard_LabelWidth">{{
                    $t("MyFollowers_ChooseOrder")
                  }}</span>
                  <el-select class="drawerCard_100Input" v-model="followForm.orderType">
                    <el-option :label="$t('MyFollowers_All')" :value="0"></el-option>
                    <el-option :label="$t('MyFollowers_order_sell')" :value="2"></el-option>
                    <el-option :label="$t('MyFollowers_order_buy')" :value="1"></el-option>

                  </el-select>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="drawerCardItem">
                  <span class="drawerCard_Label drawerCard_LabelWidth">{{
                    $t("MyFollowers_PL")
                  }}</span>
                  <el-select class="drawerCard_100Input" v-model="followForm.profitType">
                    <el-option :label="$t('MyFollowers_All')" :value="0"></el-option>
                    <el-option :label="$t('MyFollowers_pl_sell')" :value="1"></el-option>
                    <el-option :label="$t('MyFollowers_pl_buy')" :value="2"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="drawerCardBtns">
                  <el-button class="drawerCardBtn" @click="closeOrder()">{{
                    $t("MyFollowers_ClosePosition")
                  }}</el-button>
                </div>
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
        <div class="drawerBtns" v-if="checkRow.status == 0">
          <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="followSubmit(1)">{{
            $t("MyFollowers_AcceptInvitation") }}</el-button>

          <el-button class="cancelDialogBtn" @click.stop="isRefuse = true">{{
            $t("MyFollowers_RejectInvitation")
          }}</el-button>
        </div>
        <div class="refuseCard" v-if="isRefuse" ref="refuseCard">
          <p v-if="mobileFlag">
            {{
              $t("MyFollowers_RejectInvitation")
            }}
          </p>
          <el-input type="textarea" :rows="6" :placeholder="$t('MyFollowers_RejectInvitation_DEMO')"
            v-model="refuseReason">
          </el-input>
          <el-button class="confirmDialogBtn" @click="followSubmit(2)">{{
            $t("MyFollowers_Complete")
          }}</el-button>
        </div>
      </div>
    </el-drawer>
    <div class="mobileLabelTips" ref="mobileLabelTips" v-if="isLableTips">
      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
      <p>
        {{ labelTitle }}
      </p>
      <div >
          <div>
            {{ labelTxt }}
          </div>
          <br />
          <div v-if="labelSubContent">
            {{ labelSubContent }}
          </div>
      </div>
    </div>
    <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
      @confirmPopupTips="confirmPopup"
      @cancelPopupTips="cancelPopup"
      @cancelClosePopupTips="cancelClosePopup"
      ref="popupTipsRef"
      :isShowClose="isShowClose"
      :confirmTxt="confirmTxt"
      :cancelTxt="cancelTxt"
      :isHtml="isHtml"
      :fullscreenLoading="fullscreenLoading"
      :subContent="$t(subContent)"
      :content="$t(popupContent)" :btnType="btnType" />
    
      <el-dialog :title="$t(stopDialogTitle)" @close="cancelStop()" :visible.sync="stopDialogVisible"  class="closeCheckDialog"
        width="40%" top="5vh">
        <el-form :model="stopForm"  ref="stopForm" :label-position="mobileFlag ? 'top' : 'left'" @submit.native.prevent label-width="150px">
          <el-form-item :label="$t('startSelectTime')"  prop="currentSettlementBeginTime">
            <el-input disabled v-model="stopForm.currentSettlementBeginTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('InitialNetValue')" prop="beginPeriodEquity" >
            <el-input disabled v-model="stopForm.beginPeriodEquity"  autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('CurrentDeposit')" prop="currentPeriodDeposit">
            <el-input disabled v-model="stopForm.currentPeriodDeposit" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('NetSettlementValue')" prop="endPeriodEquity">
            <el-input disabled  v-model="stopForm.endPeriodEquity" autocomplete="off" ></el-input>
          </el-form-item>
          
          <el-form-item :label="$t('paymentProfitSharingRatio')" prop="shareProfitPercent">
            <el-input disabled v-model="stopForm.shareProfitPercent" placeholder="0-100"></el-input>
            <b class="formPercent">%</b>
          </el-form-item>
          <el-form-item prop="bouns" :label="$t('paymentProfitSharingMoney')">
            <el-input disabled  v-model="stopForm.bouns" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item prop="status" :label="$t('profitSharingInstructions')">
            <div>
              {{ $t("profitSharingInstructionsOne") }}
            </div>
            <div>
              {{ $t("profitSharingInstructionsTwo") }}
            </div>
            <div>
              {{ $t("profitSharingInstructionsThree") }}
            </div>
          </el-form-item>
        </el-form>
        <hr />
        <el-checkbox class="stopCheckbox" v-model="stopCheckbox">{{ $t('stopCheckTxt') }}</el-checkbox>
        <div slot="footer" class="dialog-footer">
          <el-button class="confirmDialogBtn" @click="stopSubmit()">{{
            $t('MyFollowers_confirm')
          }}</el-button>
        </div>
      </el-dialog>

  </div>
</template>

<script>
// @ is an alias to /src
import { concatParams, concatTableData, dateGmt8 } from "../../plugins/common.js";
import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'
import load2 from '@/components/moudule/Loading.vue'
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  BScroll.use(Pullup)


export default {
  name: "MyFollow",
  components: {
    PopupTips,
    load2
  },
  computed: {
    ...mapState(["checkPortfolio", "userInfo", "token", 'mobileFlag']),
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 5,
      historyCurrentPage: 1,
      historyTotal: 0,
      historyPageSize: 5,
      checkInviteDrawer: false,
      fullscreenLoading: false,
      followForm: {
        account: "",
        following: 0,
        signalMaxDrawdown: 0,
        takeProfit: 0,
        maxDrawdown: 0,
        maxVolume: 0,
        symbols: "",
        autoRecon: true,
        activation: true,
        maxDrawdownStopFollow: false,
        takeProfitStopFollow: false,
        orderType: 0,
        profitType: 0,
        cwPassword: '',
        serverId: 0,
          brokerName:''

      },
      followRule: {
        account: [
          {
            required: true,
            message: this.$t("MyFollowers_checkMt4Account"),
            trigger: "blur",
          },
        ],
        cwPassword: [
          { required: true, message: this.$t('MyFollowers_Mt4PasswordNotNull'), trigger: 'blur' },
          // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
        ],
      },
      checkRow: {},
      refuseReason: "",
      isRefuse: false,
      isFollowing: false,
      followDrawerTitle: "",
      activeName: "follow",
      historyTableData: [],
      labelTitle: '',
      labelTxt: '',
      isLableTips: false,
      accountList: [],
      isPopupVisible: false,
      iconImg:require('../../assets/images/NotifyWarning.svg'),
      isStopPopupVisible:false,
      isClosePopupVisible:false,
      btnType:1,
      popupTitle:'',
      popupContent:'',
      clickType:'',
      cancelTxt:'MyFollowers_Cancel',
      confirmTxt:'MyFollowers_confirm',
      isShowClose:false,
      totalProfit:0,
      totalVolume:0,
      brokerList:[],
      serverList:[],
      isHtml:false,
      subContent:'',
      labelSubContent:'',
      up:false,
      firstOpen:false,
      stopDialogVisible:false,
      stopForm: {
        bouns: '',
        currentPeriodDeposit: '',
        currentSettlementBeginTime: '',
        shareProfitPercent: '',
        beginPeriodEquity: '',
      },
      stopDialogTitle:'',
      stopCheckbox:false,
      isFormPercentSettingPopupVisible: true
    };
  },
  watch: {
  },
  mounted(){
    this.$refs.popupTipsRef.rendered = true
    // this.scrollFn()


    // console.log(this.$refs.popupTipsRef)
  },
  created() {
    this.getData();
    this.screenClick()
    this.getServeList()

  },
  methods: {
    formatNumberFractionDigits,
   refreshList(){
      this.currentPage = 1
      this.getData()
      // window.location.reload()
   },

   collapseChange(){
      this.$nextTick(() => {
            if(!this.firstOpen){
              this.scroll.maxScrollY = this.scroll.maxScrollY - 250
              this.firstOpen = true
            }else{
              this.scroll.maxScrollY = this.scroll.maxScrollY + 250
              this.firstOpen = false
            }
        });

    },
   showCollapse(){},
   scrollFn(){
        this.$nextTick(() => {
            if (!this.scroll) {
                this.scroll = new BScroll(this.$refs.bscroll, {
                    click: true,
                    scrollY: true,
                    probeType: 3
                });
            }else{
                this.scroll.refresh();
            }

            //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
            this.scroll.on('touchEnd', (pos) => {
                //上拉加载 总高度>下拉的高度+10 触发加载更多
                if(this.scroll.maxScrollY>pos.y + 10){
                    // console.log("加载更多")
                    this.up=true;
                    setTimeout(()=>{
                    //使用refresh 方法 来更新scroll  解决无法滚动的问题
                        if(this.tableData.length < this.total){
                          this.currentPage = this.currentPage + 1
                          this.getData('noRefresh')
                          this.scroll.refresh();
                          this.up=false;
                        }else{
                          this.up=false;
                        }

                   },1000)

                }
                // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
            })

            // console.log(this.scroll.maxScrollY)
        });
    },
   getServeList(){
      this.$axios.get("/Api/invite/BrokerList").then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          // this.mainAccountDrawer = true
          this.brokerList = dt.result;

          // this.stopForm.portfolioName = dt.result.portfolioName
          // this.tableData = concatTableData(dt.result,10)
        }
      });
    },
   cancelPopup(val){
      this.isPopupVisible = val
      this.cancelTxt = 'MyFollowers_Cancel'
      this.confirmTxt = 'MyFollowers_confirm'
      this.isShowClose = false
      this.subContent = ''
      if(this.clickType == 'stopCopy' && this.checkRow.status == 1){

        this.cancelStopPopup()
      }
   },
    confirmPopup(val){
      this.isPopupVisible = val
      this.cancelTxt = 'MyFollowers_Cancel'
      this.confirmTxt = 'MyFollowers_confirm'
      this.subContent = ''
      this.isShowClose = false
      if(this.clickType == 'noChange'){
        this.allowSubmit()
      }else if(this.clickType == 'stopCopy'){

        this.confirmStopPopup()
      }else if(this.clickType == 'closeOrder'){
        this.confirmCloseOrder()
      }else if(this.clickType == 'percentSetting'){
        this.confirmPercentSetting()
      }
    },
    getData(tag) {
      if(!this.mobileFlag || tag !== 'noRefresh'){
        this.tableData = [];

      }
      var params = {
        status: this.$route.query.status?this.$route.query.status:'',
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios.get("/Api/Invite/List" + concatParams(params)).then((res) => {
        let dt = res.data;

        if (dt.isSuccess) {
          // dt.result.pageList.forEach((item,index) =>{
          //     item.followTime = dateGmt8(item.followTime)
          // })
          this.total = dt.result.total;
          this.totalProfit = dt.result.totalProfit;
          this.totalVolume = dt.result.totalVolume;
          if(this.mobileFlag && tag == 'noRefresh'){
            dt.result.pageList.forEach((item) =>{
              this.tableData.push(item)
            })


          }else{
            this.tableData = concatTableData(
              dt.result.pageList,
              dt.result.total,
              this.currentPage,
              this.pageSize
            );
          }

          this.fullscreenLoading = false;
          if(this.mobileFlag){
            this.scrollFn()
          }

          // this.$refs.dataTable.doLayout();
        }
      });
    },
    // 查看邀请
    checkFollow(row) {
      this.isFollowing = false;
      this.isRefuse = false;
      this.checkRow = row;
      this.followDrawerTitle = this.$t("MyFollowers_ViewInvitation");
      // this.checkInviteDrawer = true
      var params = {
        id: row.id,
      };
      this.$axios
        .get("/Api/Invite/GetInvite" + concatParams(params))
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.checkInviteDrawer = true;
            this.$nextTick(function () {
              this.followForm = Object.assign(this.followForm, dt.result);
              this.followForm.forexSelectLabel = this.followForm.forexSelect
                ? this.$t("MyFollowers_Fx")
                : "";
              this.followForm.energySelectLabel = this.followForm.energySelect
                ? this.$t("MyFollowers_Energy")
                : "";
              this.followForm.metalsSelectLabel = this.followForm.metalsSelect
                ? this.$t("MyFollowers_Metal")
                : "";
              this.followForm.indicesSelectLabel = this.followForm.indicesSelect
                ? this.$t("MyFollowers_Indices")
                : "";
              this.followForm.cryptoSelectLabel = this.followForm.cryptoSelect
                ? this.$t("MyFollowers_Crypto")
                : "";
              this.followForm.symbols =
                this.followForm.forexSelectLabel +
                " " +
                this.followForm.energySelectLabel +
                " " +
                this.followForm.metalsSelectLabel +
                " " +
                this.followForm.indicesSelectLabel +
                " " +
                this.followForm.cryptoSelectLabel;
                this.followForm.maxVolume = this.followForm.maxVolume == 0?undefined:this.followForm.maxVolume
            });
            // console.log(this.followForm)
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
      // this.$alert(this.$t('checkFollowBefore'), this.$t("MyFollowers_prompt"),{
      //   confirmButtonText: this.$t('MyFollowers_confirm'),
      //   customClass:'alertClass',
      //   callback: action => {

      //   }
      // });

    },
    // 设置
    getSetting(row) {
      this.isFollowing = true;
      this.checkRow = row;
      this.isRefuse = false;
      this.followDrawerTitle = this.$t("MyFollowers_Setting");
      var params = {
        id: row.id,
      };
      // console.log(this.checkRow)
      this.$axios
        .get("/Api/Invite/GetSetting" + concatParams(params))
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.checkInviteDrawer = true;
            this.$nextTick(function () {
              this.brokerList.forEach(item => {
                if(dt.result.brokerName == item.brokerName){
                    this.selectChange(item.id)
                }
              })
              this.followForm = Object.assign(this.followForm, dt.result);
              this.followForm.forexSelectLabel = this.followForm.forexSelect
                ? this.$t("MyFollowers_Fx")
                : "";
              this.followForm.energySelectLabel = this.followForm.energySelect
                ? this.$t("MyFollowers_Energy")
                : "";
              this.followForm.metalsSelectLabel = this.followForm.metalsSelect
                ? this.$t("MyFollowers_Metal")
                : "";
              this.followForm.indicesSelectLabel = this.followForm.indicesSelect
                ? this.$t("MyFollowers_Indices")
                : "";
              this.followForm.cryptoSelectLabel = this.followForm.cryptoSelect
                ? this.$t("MyFollowers_Crypto")
                : "";
              this.followForm.symbols =
                this.followForm.forexSelectLabel +
                " " +
                this.followForm.energySelectLabel +
                " " +
                this.followForm.metalsSelectLabel +
                " " +
                this.followForm.indicesSelectLabel +
                " " +
                this.followForm.cryptoSelectLabel;
                this.followForm.maxVolume = this.followForm.maxVolume == 0?undefined:this.followForm.maxVolume
                this.followForm.orderType = 0
                this.followForm.profitType = 0
                console.log(this.followForm)
                console.log(this.serverList)
                // this.followForm.serverId = this.followForm.serverId.toString()
            });
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    closeDrawer() {
      this.$refs.followForm.resetFields();
      this.checkInviteDrawer = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // console.log('---------aaaaaaaaaa')
      this.getData(this.currentPage, this.pageSize);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log('---------aaaaaaaaaa')
      this.getData(this.currentPage, this.pageSize);
    },
    followSubmit(status) {
      if (status == 1) {
        this.$refs.followForm.validate((valid) => {
          if (valid) {
            // console.log(111111111111)
            this.btnType = 1
            this.clickType = 'noChange'
            this.popupTitle = 'noChangePassword'
            this.popupContent  = 'noChangePasswordContent'
            this.isPopupVisible = true
          }
        });
      } else {
        this.fullscreenLoading = true;
        var params = {
          id: this.checkRow.id,
          // maxDrawdown: this.followForm.maxDrawdown,
          // maxDrawdownStopFollow: this.followForm.maxDrawdownStopFollow,
          // takeProfit: this.followForm.takeProfit,
          // takeProfitStopFollow: this.followForm.takeProfitStopFollow,
          status: status,
          refuseReason: this.refuseReason,
        };
        this.$axios.post("/Api/Invite/PostInvite", params).then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

            this.getData(this.currentPage, this.pageSize)
            this.$refs.followForm.resetFields();
            this.checkInviteDrawer = false;
            this.refuseReason = ""
            //   this.getStatusSignalIR();
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        }).finally(() => {
          this.fullscreenLoading = false;
        });

      }
    },
    //同意邀请
    allowSubmit(){
      this.fullscreenLoading = true;
            var params = {
              id: this.checkRow.id,
              account: this.followForm.account,
              cwPassword: this.followForm.cwPassword,
              // maxDrawdown: this.followForm.maxDrawdown
              //   ? this.followForm.maxDrawdown
              //   : 0,
              // maxDrawdownStopFollow: this.followForm.maxDrawdownStopFollow,
              // takeProfit: this.followForm.takeProfit
              //   ? this.followForm.takeProfit
              //   : 0,
              // takeProfitStopFollow: this.followForm.takeProfitStopFollow,
              serverId:this.followForm.serverId,
              status: 1,
            };
            this.$axios.post("/Api/Invite/PostInvite", params).then((res) => {
              let dt = res.data;
              if (dt.isSuccess) {

                this.getData()
                this.$notify.success({title: this.$t('nofity_success'), message:dt.result});
                this.$refs.followForm.resetFields();
                this.checkInviteDrawer = false;
              } else {
                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
              }
            }).finally(() => {
              this.fullscreenLoading = false;
            });
    },
    // 设置保存  暂时取消
    followSettingSave(type) {
      // console.log(this.followForm)
      this.fullscreenLoading = true;
      var setValue = 0;
      if (type == 1) {
        if (this.followForm.maxDrawdown) {
          setValue = this.followForm.maxDrawdown;
        } else {
          setValue = 0;
        }
      } else {
        if (this.followForm.takeProfit) {
          setValue = this.followForm.takeProfit;
        } else {
          setValue = 0;
        }
      }
      var params = {
        id: this.checkRow.id,
        setValue: setValue,
        type: type,

      };
      // console.log(params)
      this.$axios
        .post("/Api/Invite/PostSetting", params)
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

            this.getData(this.currentPage, this.pageSize);
            this.checkInviteDrawer = false;
            this.$refs.followForm.resetFields();
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    //// 一键平仓确认走接口
    confirmCloseOrder(){
      this.fullscreenLoading = true;
      var params = {
        account: this.checkRow.account,
        orderType: Number(this.followForm.orderType),
        profitType: Number(this.followForm.profitType),
      };
      this.$axios
        .get("/Api/Invite/CloseOrder" + concatParams(params))
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

            this.getData(this.currentPage, this.pageSize);
            this.checkInviteDrawer = false;
            this.$refs.followForm.resetFields();
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    // 一键平仓
    closeOrder() {
      this.btnType = 2
      this.clickType = 'closeOrder'
      this.popupTitle = 'closeOrderTitle'
      this.popupContent = 'closeOrderContent'
      this.subContent = 'MyFollowers_stopCopingContent1'
      this.isHtml = true
      this.isPopupVisible = true

    },
    stopFollow(row) {
      // this.$confirm(
      //   this.$t("MyFollowers_stopCopingConfirm"),
      //   this.$t("MyFollowers_prompt"),
      //   {
      //     confirmButtonText: this.$t("MyFollowers_confirm"),
      //     cancelButtonText: this.$t("MyFollowers_Cancel"),
      //     type: "warning",
      //   }
      // ).then(() => {
        // console.log(row)
        if(row.status == 1){
          this.btnType = 2
          // this.clickType = 'stopCopy'
          this.stopDialogTitle = 'MyFollowers_StopCopying'
          // this.popupContent = 'MyFollowers_stopCopingContent'
          // this.subContent = 'MyFollowers_stopCopingContent1'
          // this.cancelTxt = 'stop_cancelTxt'
          // this.confirmTxt = 'stop_confirmTxt'
          this.isShowClose = true
          this.stopDialogVisible = true
          // this.isHtml = true
          this.checkRow = row

          this.$axios.get("/Api/invite/StopFollowDetail?inviteId="+row.id).then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.stopForm = dt.result;
              this.stopForm.currentPeriodDeposit = formatNumberFractionDigits(this.stopForm.currentPeriodDeposit)
              this.stopForm.beginPeriodEquity = formatNumberFractionDigits(this.stopForm.beginPeriodEquity)
              this.stopForm.endPeriodEquity = formatNumberFractionDigits(this.stopForm.endPeriodEquity)

            }
          });


        }else{
          this.btnType = 2
          this.clickType = 'stopCopy'
          this.popupTitle = 'MyFollowers_StopCopying'
          this.popupContent = 'MyFollowers_stopCopingConfirm'
          this.cancelTxt = 'MyFollowers_Cancel'
          this.confirmTxt = 'MyFollowers_confirm'
          this.isShowClose = false
          this.isPopupVisible = true
          this.checkRow = row
        }


      // });
    },
    // 停止跟随 选择是
    confirmStopPopup(val){
      const loading = this.$loading({
          lock: true,
      });
      var params = {
          id: this.checkRow.id,
          closeOrder:1
        };
        this.$axios
          .get("/Api/Invite/StopFollow" + concatParams(params))
          .then((res) => {
            let dt = res.data;

            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getData(this.currentPage, this.pageSize);
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(() =>{
            loading.close();

          });
    },
    // 停止跟随 选择否
    cancelStopPopup(){
      const loading = this.$loading({
          lock: true,
      });
      var params = {
          id: this.checkRow.id,
          closeOrder:0
        };
        this.$axios
          .get("/Api/Invite/StopFollow" + concatParams(params))
          .then((res) => {
            let dt = res.data;

            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getData(this.currentPage, this.pageSize);
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(() =>{
            loading.close();
            this.stopDialogVisible = false
          });
    },
    // 停止跟随关闭
    cancelClosePopup(val){
      this.isPopupVisible = val
      this.cancelTxt = 'MyFollowers_Cancel'
      this.confirmTxt = 'MyFollowers_confirm'
      this.isShowClose = false
    },
    // mt4选择
    selectChange(val) {
      // console.log(val)

      this.brokerList.forEach((item,index) =>{
        if(item.id == val){
          this.serverList = item.serverList
        }
      })
      // this.$axios
      //   .post("/Api/Invite/EquityLessthanMaxDrawdown", {
      //     id: this.checkRow.id,
      //     account: val,
      //   })
      //   .then((res) => {
      //     let dt = res.data;

      //     if (dt.isSuccess) {
      //       //               this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

      //       // this.getData(this.currentPage, this.pageSize)
      //     } else {
      //       this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
      //     }
      //   });
    },
    labelIconClick(title, content, subContent) {

      if (this.mobileFlag) {
        this.isLableTips = !this.isLableTips
        this.labelTitle = title
        this.labelTxt = content
        this.labelSubContent = subContent
      } else {
        this.isLableTips = false
      }
    },
    screenClick() {
      // debugger
      document.addEventListener("click", e => {
        if (this.$refs.mobileLabelTips) {
          let self = this.$refs.mobileLabelTips.contains(e.target);
          if (!self) {
            this.isLableTips = false;
            this.labelSubContent = ''

          }
        }
        if (this.$refs.refuseCard) {
          let self = this.$refs.refuseCard.contains(e.target);
          if (!self) {
            this.isRefuse = false;
          }
        }
      })

    },
    toEcharts(account){
        this.$router.push({ path: '/FollowEcharts',query:{account:account} });
    },
    getSummaries(param) {
          const { columns, data } = param;
          const sums = [];
          columns.forEach((column, index) => {
              if (index === 0) {
                  sums[index] = this.$t('echarts_total');
                  return;
              }
              const values = data.map(item => Number(item[column.property]));
              if (column.property === 'profit' || column.property === 'volume' || column.property === 'equity' || column.property === 'balance') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)

                    sums[index] =formatNumberFractionDigits(sums[index]) 
                } else {
                    sums[index] = ' '
                }
          });

          return sums;

      },
      cancelStop() {
        // console.log(this.signalForm)
        this.$refs.stopForm.resetFields();

        this.stopDialogVisible = false
      },
      stopSubmit(){
        if(this.stopCheckbox){
          this.cancelStopPopup()

        }else{
          this.$notify.error({title: this.$t('MyFollowers_prompt'), message: this.$t('stopCheckNotNull')});
        }
      },
      // 跟单比例设置
      percentSetting() {
        this.btnType = 2
        this.clickType = 'percentSetting'
        this.popupContent = 'percentSettingContent'
        this.isHtml = true
        this.isPopupVisible = true

      },
      // 确认跟单比例设置
      confirmPercentSetting(){
        this.fullscreenLoading = true;
        var params = {
          id: this.checkRow.id,
          followRate: this.followForm.following,
        };
        this.$axios.post("/Api/Invite/SetFollowRate", params).then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
            this.getData(this.currentPage, this.pageSize)
            this.$refs.followForm.resetFields();
            this.checkInviteDrawer = false;
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        }).finally(() => {
          this.fullscreenLoading = false;
        });
      }
  },
};
</script>
<style lang="less" scoped>

.formLots{
  right: 12%;
}
.pageTitle{
  justify-content: flex-start;
  align-items: center;
}
.refreshListBtnDiv {
  margin-left: 10px;
  .confirmDialogBtn {
    color: #FFF;
    text-align: center;
    font-family: 'PingFangSC-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding:8px 10px;

  }
}
.stopCheckbox{
  color: #E35972;
  font-size: 20px
}

@media screen and (max-width:767px){
  .tableDiv{
    padding-top: 40px!important;
  }
  .scrollLoading{
    height: 50px;
    text-align: center;
  }
  // .refreshListBtnDiv{
  //     position: absolute;
  //     right: 20px;
  //     top: 60px;
  //     .confirmDialogBtn{
  //       padding: 10px ;
  //       font-size: 12px;
  //       line-height: 15px;
  //     }
  // }

}
</style>

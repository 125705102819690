
<template>
    <div class="tmd-spin-nested-loading">
        <div>
            <div class="tmd-spin tmd-spin-spinning" aria-live="polite" aria-busy="true"><span
                    class="tmd-spin-dot tmd-spin-dot-spin"><i class="tmd-spin-dot-item"></i><i
                        class="tmd-spin-dot-item"></i><i class="tmd-spin-dot-item"></i><i
                        class="tmd-spin-dot-item"></i></span></div>
        </div>
    </div>
</template>



<script>
export default {
    props: {
    },
    computed: {
    },
};
</script>
<style lang="less">

.tmd-spin-nested-loading>div>.tmd-spin .tmd-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
}

.tmd-spin-dot-spin {
    transform: rotate(0deg);
    -webkit-animation: antRotate 1.2s linear infinite;
    animation: antRotate 1.2s linear infinite;
}

.tmd-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;
}

.tmd-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #4D71FF;
    border-radius: 100%;
    transform: scale(.75);
    transform-origin: 50% 50%;
    opacity: .3;
    -webkit-animation: antSpinMove 1s linear infinite alternate;
    animation: antSpinMove 1s linear infinite alternate;
}

.tmd-spin-dot-item:first-child {
    top: 0;
    left: 0;
}

.tmd-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
}

.tmd-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}

.tmd-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

@-webkit-keyframes antSpinMove {
    to {
        opacity: 1
    }
}

@keyframes antSpinMove {
    to {
        opacity: 1
    }
}

@-webkit-keyframes antRotate {
    to {
        transform: rotate(1turn)
    }
}

@keyframes antRotate {
    to {
        transform: rotate(1turn)
    }
}</style>